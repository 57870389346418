import.meta.glob(['@images/**'])
import.meta.env.DEV && import("@styles/main.sass");

import { createApp } from "petite-vue";
import { isInViewport } from "./utils/is-in-viewport";
import { initMap } from "./map";
import Swiper from 'swiper';
import SimpleLightbox from "simple-lightbox";
import { Navigation, Pagination } from 'swiper/modules';

declare global {
    const modelData: {
        title: string
        subtitle: string
        photo: string
    }

    const html_forms: any

    const dataLayer: any

    interface Window {
        loadMap: () => void
        initMap: () => void
        mapLoaded: boolean
        wpObject: {
            gMapsApi: string
            mapPosition: string
            mapAddressUrl: string
            mapIcon: string
            mapBackgroundColor: string
            mapPrimaryColor: string
        }
    }
}

(() => {
    interface Header {
        opened: boolean
        handleOpenedHeader(state: boolean): void
    }

    const headerEl = document.querySelector('[data-header]'),
        headerScope: Header = {
            opened: false,
            handleOpenedHeader(state) {
                document.body.classList.toggle("u-hiddenOverflow", state)
                headerEl[state ? 'addEventListener' : 'removeEventListener']('click', e => {
                    if (!e.target.matches('a')) return
                    this.opened = false
                });
                hidingOnScroll()
            }
        }

    headerEl && createApp(headerScope).mount(headerEl)
    if (!headerEl) return

    let lastScroll = 0

    const hidingOnScroll = () => {
        const currentScroll = window.scrollY;

        if (currentScroll > 320) {
            headerEl.classList.add('is-sticked')
            headerEl.classList[currentScroll > lastScroll ? 'add' : 'remove']('is-up')
        } else {
            headerEl.classList.remove('is-sticked', 'is-up')
        }

        lastScroll = currentScroll;
    }

    hidingOnScroll();
    window.addEventListener('scroll', () => hidingOnScroll(), { passive: true });
})();

(() => {
    new Swiper('[data-posts-carousel]', {
        direction: 'horizontal',
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        spaceBetween: 32,
        breakpoints: {
            768: {
                slidesPerView: 1.5,
            },
            1024: {
                slidesPerView: 2,
            },
            1440: {
                slidesPerView: 2.5,
            },
        },

        pagination: {
            el: '.swiper-pagination',
        },

        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        }
    });
})();

(() => {
    const section = document.querySelector('[data-models]')
    if (!section) return;

    interface ModelDialog {
        model: string
        data(): {
            title: string
            subtitle: string
            photo: string
        },
        openModelDialog(model: string): void
    }

    const dialog = section.querySelector('dialog'),
        dialogScope: ModelDialog = {
            model: Object.keys(modelData)[0],
            get data() {
                return modelData[this.model]
            },
            openModelDialog(model: string) {
                this.model = model
                dialog.showModal()
            }
        };

    createApp(dialogScope).mount(section);

    document.querySelector('[data-model-picker-opener]')?.addEventListener('click', (e) => {
        e.preventDefault();
        dialog.showModal();
    })
    document.getElementById("model-picker-close")?.addEventListener("click", e => {
        e.preventDefault();
        dialog.close();
    });

    dialog.addEventListener('click', e => {
        e.target === dialog && dialog.close();
    });
})();

(() => {
    const modal = document.getElementById('post-modal') as HTMLDialogElement,
        closeModal = document.getElementById('post-modal-close'),
        frame = document.querySelector('[data-post-frame]') as HTMLIFrameElement

    if (!modal) return

    document.querySelectorAll<HTMLElement>('.post-link').forEach(link => {
        link.addEventListener('mouseenter', () => { frame.src = link.dataset.postUrl })
        link.addEventListener('click', function (e) {
            e.preventDefault();
            frame.src = link.dataset.postUrl;
            modal.showModal();
        });
    });

    document.querySelectorAll<HTMLElement>('.FooterLinks a:not([target])').forEach(link => {
        link.addEventListener('mouseenter', () => { frame.src = link.getAttribute('href') })
        link.addEventListener('click', function (e) {
            e.preventDefault();
            frame.src = link.getAttribute('href');
            modal.showModal();
        });
    })

    closeModal.addEventListener('click', () => modal.close());

    modal.addEventListener('close', () => { frame.src = '' })
    modal.addEventListener('click', e => { e.target === modal && modal.close() });
})();

(() => {
    if (!document.querySelector('.wp-block-gallery'))
        return

    new SimpleLightbox({
        elements: '.wp-block-gallery figure > a'
    })
})();

(() => {
    window.mapLoaded = false

    const loadMap = () => {
        if (window.mapLoaded || !isInViewport(document.querySelector('[data-map-holder]'), 4))
            return

        window.mapLoaded = true

        const gMapsJs = document.createElement('script')
        gMapsJs.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyA_IckNle5OAQHtUqaSKFQHDtGe9nmJj_0&callback=initMap&libraries=&v=weekly`

        document.body.insertAdjacentElement('beforeend', gMapsJs)
    }

    document.addEventListener("DOMContentLoaded", loadMap);
    document.addEventListener("scroll", loadMap);
    window.loadMap = loadMap
})();

window.initMap = initMap;

document.addEventListener("DOMContentLoaded", () => {
    if (!window.hasOwnProperty('dataLayer')) return

    window.hasOwnProperty('html_forms')
        && html_forms?.on('success', (formElement: HTMLFormElement) => formElement && dataLayer?.push({
            event: 'form-send',
            formID: formElement.dataset.slug,
            pageUrl: window.location.href
        }));

    document.querySelectorAll<HTMLElement>('.HomeKnowUs a[href]').forEach(link => {
        const href = link.getAttribute('href')
        let type = ''

        if (href.startsWith('tel:')) type = 'phone'
        if (href.startsWith('mailto:')) type = 'email'
        if (href.includes('//linkedin.com/')) type = 'LinkedIn'

        link.addEventListener('click', () => dataLayer?.push({
            event: 'contact-click',
            name: link.parentElement.querySelector<HTMLElement>('.HomeKnowUs-section-title')?.innerText ?? '',
            type: type
        }));
    });

    document.querySelectorAll<HTMLElement>('#contact a[href]').forEach(link => {
        const href = link.getAttribute('href')
        let type = undefined

        if (href.startsWith('tel:')) type = 'phone'
        if (href.startsWith('mailto:')) type = 'email'

        type && link.addEventListener('click', () => dataLayer?.push({
            event: 'contact-click',
            name: 'Obecný kontakt',
            type: type
        }));
    });
});